import { dropdownToggle } from '../../utils/utils';
import './brand.scss';

const Brand = () => {
    const $brandButtons = document.querySelectorAll('.js-brand-button');

    $brandButtons.forEach((button) => {
        const $dropdownBlock = button.closest('.js-brand-product-info').querySelector('.js-brand-dropdown');
        button.addEventListener('click', () => {
            dropdownToggle($dropdownBlock);
            button.classList.toggle('active');
        });
    });
};

Brand();
